.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 9999px;
  font-weight: 600;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
button.primary {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
  border-width: 1px;
}
button.primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}
button.primary:disabled {
  opacity: 0.3;
}
button.primary:disabled:hover {
  cursor: not-allowed;
}

#minting-dapp .collection-status {
  display: grid;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
}
@media (min-width: 640px) {
  #minting-dapp .collection-status {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
#minting-dapp .collection-status {
  border-radius: 0.5rem;
  /* font-size: 0.875rem; */
  line-height: 1.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(248 250 252 / var(--tw-border-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
#minting-dapp .collection-status > * {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
#minting-dapp .collection-status > * .label {
  /* font-size: 0.75rem; */
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  #minting-dapp .collection-status .user-address {
    grid-column: span 2 / span 2;
  }
}
#minting-dapp .collection-status .user-address {
  overflow: hidden;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}
#minting-dapp .collection-status .user-address .address {
  width: 100%;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
#minting-dapp .collection-status .supply .label,
#minting-dapp .collection-status .current-sale .label {
  display: block;
  font-weight: 600;
}
#minting-dapp .collection-status .supply.supply,
#minting-dapp .collection-status .current-sale.supply {
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}
@media (min-width: 640px) {
  #minting-dapp .collection-status .supply.supply,
  #minting-dapp .collection-status .current-sale.supply {
    border-bottom-width: 0px;
  }

  #minting-dapp .collection-status .supply.supply,
  #minting-dapp .collection-status .current-sale.supply {
    border-right-width: 1px;
  }
}

#minting-dapp .mint-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  overflow: hidden;
  text-align: center;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
#minting-dapp .mint-widget .preview {
  padding: 2rem;
  --tw-bg-opacity: 1;
  /* background-color: rgb(199 210 254 / var(--tw-bg-opacity)); */
}
#minting-dapp .mint-widget .preview img {
  margin: auto;
  max-height: 13rem;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.4));
}
#minting-dapp .mint-widget .price {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
#minting-dapp .mint-widget > * {
  width: 100%;
}
#minting-dapp .mint-widget > *:not(:last-child) {
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}
#minting-dapp .mint-widget .controls {
  display: flex;
  align-items: stretch;
}
#minting-dapp .mint-widget .controls > * {
  border-radius: 0px;
  border-width: 0px;
}
#minting-dapp .mint-widget .controls .decrease,
#minting-dapp .mint-widget .controls .mint-amount {
  border-right-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}
#minting-dapp .mint-widget .controls .mint-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}
#minting-dapp .mint-widget .controls .primary {
  border-width: 0px;
}
